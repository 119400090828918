import { Progress, Form, InputNumber } from "antd";
import ReactTooltip from "react-tooltip";
import React, { useEffect, useState } from "react";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import FTM from "../images/ftm-logo.svg";
import AVAX from "../images/avax-logo.svg";
import MATIC from "../images/matic-logo.svg";
import {
  XDFIRE_ADDRESS,
  XDFIRE_ABI,
  USDC_ABI,
  USDC_ADDRESS,
} from "../constants";

function Card(props: any) {
  const {
    saleContract,
    swapDisabled,
    selectedNetwork,
    network,
    injectedProvider,
    currentPrice,
    setCurrentPrice,
    signer,
    usdcBalance,
  } = props;

  const { ethers } = require("ethers");
  const [valid, setValid] = useState(false);
  const [tooltip, showTooltip] = React.useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [depositAmount, setDepositAmount] = useState<number>(0.0);
  const [minDefireAmount, setMinDefireAmount] = useState<number>(0.0);
  const [maxPrice, setMaxPrice] = useState<number>(0.0);
  const [allocatedTokens, setAllocatedTokens] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [percent, setPercent] = useState(0);
  
 
  useEffect(() => {
    let tempMaxPrice = currentPrice * 1.1;
    tempMaxPrice = parseFloat(tempMaxPrice.toFixed(2));
    setMaxPrice(tempMaxPrice);
  }, [currentPrice]);

  useEffect(() => {
    if (depositAmount && maxPrice) {
      const amount = depositAmount / maxPrice;
      setMinDefireAmount(parseFloat(amount.toFixed(2)));
    }
  }, [depositAmount, maxPrice]);
  useEffect(() => {
    if (window.ethereum) {
      getTotalSupply();
      getTotalTokensAllocated();
      
    } else {
      console.log("No web3 browser");
    }
  }, []);

  useEffect(() => {
    calculatePercent(totalSupply, allocatedTokens);
  }, [allocatedTokens, totalSupply]);

  const onFinish = (values: any) => {
    setValid(true);
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    setValid(false);
    console.log("Failed:", errorInfo);
  };

  const networkInfo = (network: any) => {
    switch (network) {
      case "FTM":
        return { img: FTM, text: "Fantom" };
      case "AVAX":
        return { img: AVAX, text: "Avalanche" };
      case "MATIC":
        return { img: MATIC, text: "Polygon" };
      default:
        return { img: FTM, text: "Fantom" };
    }
  };

  const calculatePercent = (a: any, b: any) => {
    const percent = (a / b) * 100;
    setPercent(percent);
  };

  const depositAmountChange = (e: any) => {
    setDepositAmount(parseFloat(e.target.value));
    !e.target.value && setMinDefireAmount(0.0);
  };

 

  const getApprovement = async () => {
    if (valid) {
      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let signer = provider.getSigner();
        let usdcContract = new ethers.Contract(USDC_ADDRESS, USDC_ABI, signer);
        await usdcContract.approve(
          XDFIRE_ADDRESS,
          depositAmount * 10 ** 6
        );
        setIsApproved(true);
      } catch (error) {
        console.log(error)
      }
    }
  };
  const getTotalSupply = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let contract = new ethers.Contract(XDFIRE_ADDRESS, XDFIRE_ABI, provider);
      let totalSupply = await contract.totalSupply();
      totalSupply = ethers.utils.formatUnits(totalSupply, 18);
      totalSupply = Number(totalSupply).toFixed(2);
      setTotalSupply(totalSupply);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const getTotalTokensAllocated = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let contract = new ethers.Contract(XDFIRE_ADDRESS, XDFIRE_ABI, provider);
      let totalTokenAllocated = await contract.TOTAL_TOKENS_ALLOCATED();
      totalTokenAllocated = ethers.utils.formatUnits(totalTokenAllocated, 18);
      totalTokenAllocated = Number(totalTokenAllocated).toFixed(2);
      setAllocatedTokens(totalTokenAllocated);
    } catch (error) {
      console.log(error)
  };
}

  const deposit = async () => {
    if (valid) {
      if (!isApproved) {
        toast.error("Please approve first");
        return false;
      }
      try {
        let text = "Hello World!";
        let bytes32 = ethers.utils.formatBytes32String(text);
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let signer = provider.getSigner();
        let contract = new ethers.Contract(XDFIRE_ADDRESS, XDFIRE_ABI, signer);
        if(depositAmount >= usdcBalance){
        const transaction = await contract.depositStable(
          depositAmount * 10 ** 6,
          ethers.utils.parseEther(minDefireAmount.toString()),
          bytes32
        );

        await transaction.wait();
        const lastPrice = await saleContract.getLastPrice();

        lastPrice && setCurrentPrice(ethers.utils.formatEther(lastPrice));

        const amount = depositAmount / (currentPrice * 1.1);
        setMinDefireAmount(parseFloat(amount.toFixed(2)));
        }else{
          toast.error("Insufficient USDC balance");
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div
      className={
        selectedNetwork !== network
          ? "relative opacity-70 blur-[2px]	scale-95 cursor-not-allowed hidden lg:block"
          : "relative transition-all delay-300"
      }
    >
      <div className="flex lg:block relative -top-8 lg:absolute left-0 lg:top-0 lg:-left-11 text-defire-water ">
        <img
          className="h-8 w-8"
          src={networkInfo(network).img}
          alt={`${network} logo`}
        />
        <span className="pl-2 lg:pl-0 w-full lg:rotate-180 leading-8 pb-2 lg:[writing-mode:vertical-lr] font-bold text-md">
          {networkInfo(network).text}
        </span>
      </div>

      <div className="absolute top-0 sm:-top-32 flex justify-center w-full z-2">
        <Progress
          //width={320}
          width={250}
          type="circle"
          showInfo={false}
          percent={percent}
          format={(percent) => `${percent} Token`}
          strokeColor={"#F64B51"}
          trailColor={"rgba(246, 75, 81, 0.2)"}
        />
        <div className="absolute top-24 text-5xl text-defire-water flex flex-col text-center">
          <span>{totalSupply} of</span>
          <span className="text-sm">{allocatedTokens} DFIRE </span>
        </div>
      </div>

      <div className="sm:px-4 pb-16 pt-64 text-defire-water bg-defire-light-purple bg-opacity-20 shadow-lg h-auto rounded-xl">
        <div className="px-10">
          <div className="flex items-center mb-4">
            <p className="font-bold text-lg text-defire-cyan">
              Price Tolerance
            </p>
            <span
              data-tip="The current price updates after every deposit. If another transaction executes between when you loaded the front-end and when your transaction executes, you may pay more than the displayed current price. In most cases the difference will be non-existent or negligible.
<br/><br/>Similar to how you can set slippage on a DEX, here you can set the maximum price you're willing to pay per xDFIRE and if you would pay more, your transaction will revert. By default, the maximum price is set 10% higher than the current price. If you prefer a different price tolerance, simply enter it into the maximum price field."
              className="text-md text-defire-water ml-2"
              onMouseEnter={() => showTooltip(true)}
              onMouseLeave={() => {
                showTooltip(false);
                setTimeout(() => showTooltip(true), 50);
              }}
            >
              ⓘ
            </span>
            {tooltip && <ReactTooltip effect="solid" className={"tooltip"} html={true}/>}
          </div>
          <div className="grid grid-cols-2 gap-1">
            <div className="flex flex-col mt-2">
              <span className="text-defire-water font-bold ">
                Current Price $
              </span>
              <span className="field pl-3 pr-3 leading-[50px] mt-2">
                {currentPrice}
              </span>
            </div>
            <div className="flex flex-col">
              
              <Form
             className="!font-SpaceGrotesk mt-2"
             name="Token"
             initialValues={{ remember: true }}
             onFinish={onFinish}
             onFinishFailed={onFinishFailed}
             layout="vertical">
            <Form.Item
              label={
                <label className="text-defire-water font-bold">Maximum Price ($)</label>
              }
              name="Maximum Price ($)"
            >
              <input
                disabled={network !== selectedNetwork}
                className="field pl-3 pr-3 leading-[50px] "
                placeholder="Enter Amount"
                onChange={(e: any) => {
                  setMaxPrice(
                    isNaN(parseFloat(e.target.value))
                      ? 0
                      : parseFloat(e.target.value)
                  );
                }}
                value={maxPrice}
              />
            </Form.Item>
            </Form>
            </div>
          </div>
          <span className="text-[10px] text-defire-red font-extrabold">
          The transaction will not go through if current xDFIRE price is greater than Maximum Price.
          </span>

          <p className="font-bold text-lg text-defire-cyan mt-6 mb-4">
            Minimum xDFIRE Received
          </p>
          <p className="field text-center leading-[50px]">
            {minDefireAmount} DEFIRE
          </p>

          <Form
            className="!font-SpaceGrotesk mt-6"
            name="Token"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              label={
                <label className="text-defire-water">Deposit Amount ($)</label>
              }
              name="Deposit Amount ($)"
            >
              <input
                disabled={network !== selectedNetwork}
                className="field w-full pt-1.5 mr-4"
                placeholder="Enter the amount of USDC you wish to deposit. The minimum is 2 USDC."
                value={depositAmount}
                onChange={depositAmountChange}
              />
            </Form.Item>
            

            <button
              disabled={!swapDisabled || network !== selectedNetwork}
              className="mt-10 w-full mb-2 main-button disabled:text-opacity-50 disabled:text-defire-water "
              onClick={getApprovement}
            >
              Authorise USDC
            </button>
            <button
              disabled={!swapDisabled || network !== selectedNetwork}
              className="w-full main-button bg-defire-red text-defire-water"
              onClick={deposit}
            >
              Deposit
            </button>
          </Form>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}
export default Card;
