import { useCallback, useEffect, useState } from "react";
import { Progress } from "antd";

import Footer from "./components/navigation/footer";
import Navbar from "./components/navigation/navbar";
import Card from "./components/card";
import Terms from "./components/terms";
import InformationBanner from "./components/informationBanner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3Modal from "web3modal";
import { providerOptions } from "./helpers/providerOptions";
import Spinner from "./components/spinner";
import MainLogo from "./components/mainLogo";
import defireIcon from "./images/defireIcon.svg";
import gDFIREIcon from "./images/gDFIREIcon.svg";
import USDC from "./images/usdc-logo.svg";
import React from "react";
import {
  XDFIRE_ADDRESS,
  XDFIRE_ABI,
  USDC_ABI,
  USDC_ADDRESS,
} from "./constants";
import Web3 from "web3";
import { utils } from "ethers";


function App() {
  const [injectedProvider, setInjectedProvider] = useState();
  const [saleContract, setSaleContract] = useState();
  const [address, setAddress] = useState("");
  const [dfireInfoAmount, setdfireInfoAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [depositedAmount, setDepositedAmount] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [progressPercent, setProgressPercent] = useState(0);
  const [provider, setProvider] = useState<any>();
  const [library, setLibrary] = useState();
  const [chainId, setChainId] = useState(null);
  const [signer, setSigner] = useState();
  const [swappedAmount, setSwappedAmount] = useState(0);
  const [usdcBalance, setUsdcBalance] = useState(0);

  const { ethers } = require("ethers");

  const web3Modal = new Web3Modal({
    cacheProvider: true,
    theme: "light",
    providerOptions, // required
  });

  const topInformation = [
    { title: "My DFIRE Amount:", amount: dfireInfoAmount, icon: defireIcon },
    { title: "Swapped Amount:", amount: swappedAmount, icon: USDC },
  ];
  const logoutWallet = async () => {
    await web3Modal.clearCachedProvider();
    setAddress("");
    setChainId(null);
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };
  const connectWallet = async () => {
    const provider = await web3Modal.connect();
    const library = new ethers.providers.Web3Provider(provider);
    await library.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: utils.hexValue(137) }],
    });
    const accounts = await library.listAccounts();
    const network = await library.getNetwork();
    setProvider(provider);
    setLibrary(library);
    const web3 = new Web3(provider);
    const addresses = await web3.eth.getAccounts();
    if (accounts) setAddress(addresses[0]);
    setChainId(network.chainId);
    const tempProvider = new ethers.providers.Web3Provider(
      web3.currentProvider
    );
    setInjectedProvider(tempProvider);
    const _signer = tempProvider.getSigner();
    setSigner(_signer);
  };
  const getContract = async () => {
    try {
      let provider = await new ethers.providers.Web3Provider(window.ethereum);
      const contract = await new ethers.Contract(
        XDFIRE_ADDRESS,
        XDFIRE_ABI,
        provider
      );
      setSaleContract(contract);
      let xdfireBalance = await contract.balanceOf(address);
      xdfireBalance = ethers.utils.formatUnits(xdfireBalance, 18);
      xdfireBalance = Number(xdfireBalance).toFixed(2);
      setdfireInfoAmount(xdfireBalance);
    } catch (error) {
      console.log(error)
    }
  };
  const getUSDCBalance = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const usdcContract = new ethers.Contract(USDC_ADDRESS, USDC_ABI, signer);
    let balance = await usdcContract.balanceOf(signer.getAddress());
    balance = ethers.utils.formatUnits(balance, 6);
    balance = Number(balance).toFixed(2);
    setUsdcBalance(balance);
  };
  const connectWalletCallback = useCallback(async () => {
    await connectWallet();
  }, [Web3, web3Modal]);

  const getTotalDeposited = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let contract = new ethers.Contract(XDFIRE_ADDRESS, XDFIRE_ABI, provider);
      let totalStableCollected = await contract.totalStableCollected();
      totalStableCollected = ethers.utils.formatUnits(totalStableCollected, 6);
      setDepositedAmount(totalStableCollected);
    } catch (error) {
      console.log(error)
    }
  };
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: any) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAddress(accounts[0]);
      };

      const handleChainChanged = (_hexChainId: any) => {
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        console.log("disconnect");
        logoutWallet();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
      getContract();
      getTotalDeposited();
      getCurrentPrice();
      getUSDCBalance();
    } else {
      toast.error("No Web3 wallet found");
    }
  }, []);

  useEffect(() => {
    if (provider) {
      getContract();
      getTotalDeposited();
      getCurrentPrice();
      getSwappedAmount();
    }
  }, [address]);

  const getCurrentPrice = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let contract = new ethers.Contract(XDFIRE_ADDRESS, XDFIRE_ABI, provider);
      let currentPrice = await contract.getLastPrice();
      currentPrice = ethers.utils.formatUnits(currentPrice, 18);
      currentPrice = Number(currentPrice).toFixed(2);
      setCurrentPrice(currentPrice);
    } catch (error) {
      console.log(error)
    }
  };
  const calculateProgressPercent = () => {
    let number1 = currentPrice - 9.36;
    let number2 = number1 / 6.24;
    let percent = number2 * 100;
    setProgressPercent(percent);
  };

  const getSwappedAmount = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let signer = provider.getSigner();
      let contract = new ethers.Contract(XDFIRE_ADDRESS, XDFIRE_ABI, signer);
      let getInvestorInfo = await contract.investorInfo(address);
      getInvestorInfo = getInvestorInfo[0];
      getInvestorInfo = ethers.utils.formatUnits(getInvestorInfo, 6);
      setSwappedAmount(getInvestorInfo);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    calculateProgressPercent();
  }, [currentPrice, address]);

  const priceProgressBar = () => {
    return (
      <div className="flex ">
        {/* min historical value */}
        <span className="text-xs leading-6">$9.36</span>
        <Progress
          className="mx-2"
          percent={progressPercent}
          showInfo={false}
          strokeColor={"#F64B51"}
          trailColor={"rgba(246, 75, 81, 0.2)"}
        />
        {/* max historical value */}
        <span className="text-xs leading-6">$15.6</span>
      </div>
    );
  };

  const bottomInformation = [
    {
      title: "Total Deposits:",
      amount: Number(depositedAmount).toFixed(2),
      icon: USDC,
    },
    {
      title: "DEFIRE Price:",
      amount: currentPrice,
      icon: defireIcon,
      progresBar: priceProgressBar(),
    },
  ];

  const addTokenToWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: XDFIRE_ADDRESS,
              symbol: "xDFIRE",
              decimals: 18,
              image: "https://defire.finance/xDFIRE.svg",
            },
          },
        });
      } catch (error) {
        console.log(error)
      }
    }
  };

  return (
    (isLoading ? <Spinner /> : null) || (
      <div className="font-SpaceGrotesk h-auto customGradient">
        <Navbar
          web3Modal={web3Modal}
          connectWallet={connectWalletCallback}
          logoutWallet={logoutWallet}
          addTokenToWallet={addTokenToWallet}
        />
        <Terms></Terms>

        <section className="container mt-20 h-auto">
          <MainLogo />

          <div className="mt-32 sm:flex justify-around">
            {topInformation &&
              topInformation.map((info, i) => {
                return <InformationBanner info={info} key={i} />;
              })}
          </div>

          <div className="mt-32 sm:mt-64 mb-20 flex justify-center lg:gap-14">
            <Card
              injectedProvider={injectedProvider}
              saleContract={saleContract}
              address={address}
              swapDisabled={injectedProvider}
              selectedNetwork={"MATIC"}
              network={"MATIC"}
              currentPrice={currentPrice}
              usdcBalance={usdcBalance}
            />
          </div>

          <div className="sm:flex justify-between">
            {bottomInformation &&
              bottomInformation.map((info, i) => {
                return <InformationBanner info={info} key={i} />;
              })}
          </div>
        </section>

        <Footer />
        <ToastContainer />
      </div>
    )
  );
}

export default App;
