import defireLogo from "../../images/DeFIRE_lockup_3px.svg";
import React from "react";

function Navbar(props: any) {
  const { connectWallet, logoutWallet, web3Modal,addTokenToWallet } = props;
  
  
  return (
    <nav className="p-4 sm:p-7 flex justify-between w-full">
      <img
        className="h-10 mt-1 sm:mt-0 sm:self-center"
        src={defireLogo}
        alt="Defire Typography Logo"
      />
      <div className="flex justify-around items-end w-96">
        <button className="main-button w-32 mt-4 sm:mt-0" onClick={addTokenToWallet}>
          Add Token to Wallet
        </button>
        {web3Modal && web3Modal.cachedProvider ? (
          <button
            className="main-button mr-6 w-32 mt-4 sm:mt-0"
            onClick={logoutWallet}
          >
            Logout Wallet
          </button>
        ) : (
          <button
            className="main-button ml-2 w-32 mt-4 sm:mt-0"
            onClick={connectWallet}
          >
            Connect Wallet
          </button>
        )}
      </div>
    </nav>
  );
}
export default Navbar;
