import React from "react";

function MainLogo() {
  return (
    <div className="flex flex-col justify-center items-center">
      <p className="text-6xl text-defire-water font-medium">Community Sale Event</p>
      <p className="text-2xl text-red-500 font-extrabold">Please ensure your wallet is on Polygon Mainnet</p>
    </div>
  );
}

export default MainLogo;
