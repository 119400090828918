import React, { useEffect } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "345px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Terms() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    openModal();
  }, []);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
      >
        <div className="font-SpaceGrotesk">
          <div className="font-bold text-lg text-center mb-3 leading-loose">
            Terms and conditions
          </div>
          <div className="flex flex-col justify-center items-center">
            <p>PLEASE READ THESE TERMS AND CONDITIONS OF TOKEN SALE CAREFULLY. THE
            TERMS AND CONDITIONS SHALL NOT AND CANNOT BE CONSIDERED AS AN
            INVITATION TO ENTER INTO AN INVESTMENT OR SALE OF REGULATED
            FINANCIAL INSTRUMENTS. THE TERMS AND CONDITIONS (THE “T&C” OR
            “TERMS”) APPLY TO THE BUYER OF THE xDFIRE UTILITY TOKEN (HEREINAFTER
            “xDFIRE” or “xDFIRE TOKEN”) AND CONSTITUTE AN AGREEMENT BETWEEN
            DEFIRE DAO AND YOU. PLEASE READ THESE TERMS CAREFULLY BEFORE MOVING
            ONTO THE TOKEN SALE.</p>  <br/>
            <p>BY CLICKING ACCEPT BELOW AND ACCESSING THE TOKEN
            SALE, YOU ACKNOWLEDGE THAT YOU HAVE READ AND AGREE TO THE FULL TERMS
            AND CONDITIONS AS PROVIDED HERE: <a href="https://sale-round-2-git-master-defire.vercel.app/terms" target="_blank" className="text-red-500 font-bold">https://sale.defire.org/terms</a></p> 
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <button className="main-button w-32" onClick={closeModal}>
            Accept
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Terms;
